import React from 'react'
import './Addonbanner.scss'

export default function Paragraph(props) {
  return (
    <div 
      id={props.id}
      className={"flex flex-col w-full"}
      // style={{lineHeight: `${props.lineHeight}`}}
    > 
        <div 
          className='heading text-center flex-1'
          style={{
            fontWeight : '500',
            letterSpacing : '1.5px',
            color : 'black',
            fontStyle : 'italic'
          }}
        >
          {
            props.heading || "Heading"
          }
        </div>
       <div 
        className='flex-1 text-medium text-bold-sm'
        style={{
          letterSpacing : '1px'
        }}
        >
        {props.text}
       </div>
    </div>
  )
}
