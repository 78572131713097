import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./Footer.scss";
import fbIcon from "../../Assets/Images/navbar/facebook-icon.svg";
import instaIcon from "../../Assets/Images/navbar/instagram-icon.svg";
import linkedinIcon from "../../Assets/Images/navbar/linkedIn-icon.svg";
import twIcon from "../../Assets/Images/navbar/twitter-icon.svg";
import { SOCIALMEDIA_LINKS } from "../../Assets/Constant";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import Container1 from "../Container/Container1";
const mapStateToProps = ({ categories, auth }) => ({
  categories,
  auth,
});

const Footer = () => {
  //   const { auth } = useSelector(mapStateToProps);
  //   const { showLoginPopup = false, isLoggedIn, userDetails = {} } = auth;
  const current_year = new Date().getFullYear();
  const {
    categories: { list: categoryList = [] },
    auth,
  } = useSelector(mapStateToProps);
  const { isLoggedIn } = auth;

  const formattedCategories = categoryList;

  const FooterCategoryList = () => {
    return (

      <div className="footer-categories">
        <div className="flex flex-col gap-5">

          <ul className="ul-style-none flex flex-col gap-5">
            {
              formattedCategories && formattedCategories.length > 0 ?
                (
                  <>
                    {
                      formattedCategories.map((category, index) => {
                        if (index <= formattedCategories.length / 2) {
                          return (
                            <li>
                              <Link
                                to={`/categories/${category.name}`}
                                key={`category.name`}
                                className="text-first text-medium regular-bold"
                              >
                                {category.name}
                              </Link>
                            </li>
                          );
                        }
                      })

                    }
                  </>
                ) :
                null
            }
          </ul>


          <ul className="ul-style-none flex flex-col gap-5">
            {
              formattedCategories && formattedCategories.length > 0 ?
                (
                  <>
                    {formattedCategories.map((category, index) => {
                      if (index > formattedCategories.length / 2) {
                        return (
                          <li>
                            <Link
                              to={`/categories/${category.name}`}
                              key={`category.name`}
                              className="text-first text-medium regular-bold"
                            >
                              {category.name}
                            </Link>
                          </li>
                        );
                      }
                    })}
                  </>
                )
                :
              null
            }
          </ul>

        </div>
      </div>
    )
  }

  const FooterNavLinks = () => {
    const {auth} = useSelector(mapStateToProps);
    return (
      <ul className="ul-style-none flex flex-col gap-5">
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/"
            className="text-first text-medium regular-bold"
          >
            Home
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/"
            className="text-first text-medium regular-bold"
          >
            Categories
          </a>
        </li>
        {
          auth.tenantDetails?.template_details?.about_us &&
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/privacy-policy#Aboutus"
            className="text-first text-medium regular-bold"
          >
            About Us
          </a>
        </li>
        }


        {
          auth.tenantDetails?.template_details?.return_policy &&
          <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/privacy-policy#ReturnPolicy"
            className="text-first text-medium regular-bold"
          >
            Return Policy
          </a>
        </li>
        }
      {
          auth.tenantDetails?.template_details?.privacy_policy &&
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/privacy-policy#PrivacyPolicy"
            className="text-first text-medium regular-bold"
          >
            Privacy Policy
          </a>
        </li>
        }
       {
          auth.tenantDetails?.template_details?.refund_policy &&
          <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/privacy-policy#RefundPolicy"
            className="text-first text-medium regular-bold"
          >
            Refund Policy
          </a>
        </li>

       }
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="/privacy-policy#ContactUs"
            className="text-first text-medium regular-bold"
          >
            Contact Us
          </a>
        </li>

        <li></li>
      </ul>
    )
  }

  const FooterSocialMediaLink = () => {
    return (
      <ul className="ul-style-none flex flex-row gap-10">
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href={SOCIALMEDIA_LINKS['FACEBOOK']}
          >
            <FaFacebook color="#30363C" size={'1.3rem'} />
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href={SOCIALMEDIA_LINKS['TWITTER']}
          >
            <FaTwitter color="#30363C" size={'1.3rem'} />
          </a>
        </li>

        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href={SOCIALMEDIA_LINKS['INSTAGRAM']}
          >
            <FaInstagram color="#30363C" size={'1.3rem'} />
          </a>
        </li>
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href={SOCIALMEDIA_LINKS['LINKEDIN']}
          >
            <FaLinkedin color="#30363C" size={'1.3rem'} />
          </a>
        </li>
        <li>
          <a href="mailto:ziyuzabi@gmail.com">
          </a>
        </li>
      </ul>
    )

  }

  return (
    <div 
      className="footer-container"
    >
      <Container1
        header1={auth.tenantDetails?.title || 'Pharmacy'}
      >
        <div
          className="flex responsive-flex-row"
          style={{
            paddingTop : '1rem',
            gap : '30px'
          }}
        >
          <Container1
            header2={'Useful Links'}
          >
            <div
              style={{
                paddingTop : '0.5rem'
              }}
            >
              <FooterNavLinks/>
            </div> 
          </Container1>
          {
            isLoggedIn &&
            <Container1
              header2={'Featured Categories'}
            >
              <div
                style={{
                  paddingTop : '0.5rem'
                }}
              >
              <FooterCategoryList/>
              </div>
            </Container1>
          }
          <Container1
            header2={'Follow Us on'}
          >
            <div
              style={{
                paddingTop : '0.5rem'
              }}
            >
              <FooterSocialMediaLink/>
            </div>
          </Container1>
        </div>
      </Container1>
      <p 
        className="text-small text-first semi-bold"
        style={{
          float  : 'right',
          marginTop : '1rem',
        }}
      >
        © {current_year} {auth.tenantDetails?.title} All rights reserved.
      </p>
    </div>
  );
};

export default Footer;