import React from "react";
import close from "./close.svg";
import "./CheckoutProduct.scss";
import AddToCartButton from "../../AddToCartButton";
import Tooltip from "../../ProductCard/Tooltip";
import crossIcon from '../../../Assets/Icons/cross-icon.png'
import { MdOutlineCurrencyRupee } from "react-icons/md";

export default function CheckoutProduct({
    photo,
    product,
    description,
    quantity,
    price,
    final_price,
    removeItem,
    id,
    readyOnly,
    quantity_remaining,
    showStrikeThrough,
    discount,
    out_of_stock
}) {
    if (quantity_remaining < quantity) {
        out_of_stock = true
    }
    else if (quantity == quantity_remaining) {
        out_of_stock = false
    }
    return (

        <div className="checkout-product">

            {/* checkout product image  */}
            <div className={`img-div ${out_of_stock ? "fade-card" : ''}`}>
                {!readyOnly && 
                (
                    <button onClick={() => removeItem()} style={{
                        outline : 'none',
                        border : 'none',
                        backgroundColor:'transparent',
                        height: '2rem',
                        aspectRatio : 1,
                        position : 'absolute',
                        top: 0, 
                        right: 0,
                    }}>
                        <img src={crossIcon} alt=""/>
                    </button>
                )}
                <img src={photo} alt="No Image" />
            </div>

            {/* checkout product details */}
            <div className="checkout-details">

                <div 
                    className={`flex flex-col justify-around h-full`}
                    style={{
                        marginLeft : '1%',
                        minHeight : '10rem'
                    }}
                >

                    <div className={`${out_of_stock ? "fade-card" : ''}`}>

                        <div className="text-uppercase text-large text-bold-md">
                            {product}
                        </div>

                        {
                            quantity > 
                            quantity_remaining && quantity_remaining == 0 
                            && out_of_stock ?
                            <p className="out-of-stock">Out Of Stock</p> : null
                        }
                        {
                            quantity > 
                            quantity_remaining >= 1 && quantity_remaining != 0 
                            && out_of_stock ?
                            <p className="out-of-stock">Only {quantity_remaining} item left! </p> : null
                        }
                    </div>

                    <div 
                        className="flex flex-row text-medium items-center"
                        style={{
                            gap : '5px'
                        }}
                    >

                        <div className="flex flex-row items-center">
                            <span>
                                <MdOutlineCurrencyRupee />
                                {parseFloat(price) !== 0 ? parseFloat(price) : "pending"}
                            </span>
                            <span className="">x{parseInt(quantity)}</span>
                        </div>

                        <p>=</p>

                        <div className="flex flex-row items-center">
                            <span>
                                <MdOutlineCurrencyRupee />
                                { 
                                    !parseFloat(final_price) ? "Pending" : 
                                    parseFloat(price) * quantity
                                }
                            </span>
                            <span className="checkout-product-quantity">x{parseInt(quantity)}</span>
                        </div>
                    </div>

                    {
                        !readyOnly 
                        && (
                            <div className="">
                                <AddToCartButton
                                    product=
                                    {{
                                        title: product,
                                        price,
                                        quantity,
                                        description,
                                        image: photo,
                                        id,
                                        quantity_remaining,
                                    }}
                                    className={'btn-inactive'}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}
