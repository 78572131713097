import React from "react";
import "./Offers.scss";
import OfferCard from "../OfferCard/OfferCard";
import { useState } from "react";
import { useEffect } from "react";
import { productAPI } from "../../../Api";
import Bannerv2 from "../Bannerv2";
import Slideshow from "../Bannerv2/Slideshow";

const imgUrl2 = [
  {
    desktop_view_image : 'https://cms-contents.pharmeasy.in/homepage_top_categories_images/4cb2baf3234-Fullbody.png?dim=360x0'
  },
  {
    desktop_view_image : 'https://cms-contents.pharmeasy.in/homepage_top_categories_images/e1a18d8deac-Vitamins.png?dim=360x0'
  },
  {
    desktop_view_image : 'https://cms-contents.pharmeasy.in/homepage_top_categories_images/1e927857c26-Diabetes.png?dim=360x0'
  },
  {
    desktop_view_image : 'https://cms-contents.pharmeasy.in/homepage_top_categories_images/e1c60c444bf-Fever.png?dim=360x0'
  },
  {
    desktop_view_image : 'https://cms-contents.pharmeasy.in/homepage_top_categories_images/7b238cdbb60-womencare.png?dim=360x0'
  },
  {
    desktop_view_image : 'https://cms-contents.pharmeasy.in/homepage_top_categories_images/71fb3c06e71-Thyroid.png?dim=360x0'
  },
  {
    desktop_view_image : 'https://cms-contents.pharmeasy.in/homepage_top_categories_images/6b775dd8478-covid.png?dim=360x0'
  },
]

const Offers = () => {
  const [offersData, setoffersData] = useState([]);
  const offerFetcher = async () => {
    try {
      let response = await productAPI.fetchOffers();
      if (response) {
        response.data.sort(function (a, b) {
          let key1 = a.priority
          let key2 = b.priority
          if (key1 < key2) {
            return -1;
          }
          else if (key1 > key2) {
            return 1;
          }
          return 0;
        });
        setoffersData(response.data);
      }
      // console.log(response);
    } catch (error) {
    }
  };
  
  useEffect(() => {
    offerFetcher();
  }, []);

  return (
    <>
      {offersData && <Slideshow images={offersData} />}
    </>
  );
};

export default Offers;
