import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Banner,
  Categories,
  Foryou,
  Navbar,
  Offers,
  Help,
  Bottom,
  Install,
  WhyShop,
  Footer,
  Login,
  Overlay,
  Modal,
  Navigation,
  SearchResults,
} from "../../Components";

import { actionsCreator } from "../../Redux/actions/actionsCreator";
import giftIcon from "../../Assets/Images/gifs/gift.gif";
import medkit from "../../Assets/Images/medkit.png";
import girlwithmeds from "../../Assets/Images/girlwithmeds.png";
import bluebg from "../../Assets/Images/bluebg.png";

import tablets from "../../Assets/Images/tablets.png";
import playVideo from "../../Assets/Images/play-video.png";
import doctorVideo from "../../Assets/Images/doctor-video.png";

import { FaPlayCircle } from "react-icons/fa";
import "./Home.scss";
import { welcomeModalHandler } from "../../Utils";
import Bannerv2 from "../../Components/Homepage/Bannerv2";
import Slideshow from "../../Components/Homepage/Bannerv2/Slideshow";
import Dashboard from "../dashboard";
import ProductsSlider from "../../Components/ProductCategory/Products";
import Container1 from "../../Components/Container/Container1";
import { CiSearch } from "react-icons/ci";
import Caraousel from "../../Components/Caraousel/Caraousel";
import { useHistory } from "react-router-dom";
import useDebounce from "../../Hooks/useDebounce";

const mapStateToProps = ({ auth, categories = {} }) => ({
  auth,
  categories,
});

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    auth,
    categories: { list: categoryList },
  } = useSelector(mapStateToProps);

  const { isLoggedIn } = auth;
  const [offers, setOffers] = useState(true);
  const [subcategory, setSubcategory] = useState([]);

  const subcategoryAdd = () => {
    let temp_sub_category = [];
    categoryList.map((item) => {
      item.sub_categories &&
        item.sub_categories.map((subitem) => {
          if (subitem.home_page) {
            temp_sub_category.push(subitem);
          }
        });
    });
    setSubcategory(temp_sub_category);
  };
  useEffect(() => {
    subcategoryAdd();
  }, [categoryList]);

  const fetchCartDetails = () => {
    dispatch(actionsCreator.FETCH_CART_DETAILS());
  };

  const fetchFavouriteProducts = () => {
    dispatch(actionsCreator.FETCH_FAVOURITE_PRODUCTS());
  };

  // const [modal, setModal] = useState(true);
  useEffect(() => {
    if (isLoggedIn) {
      fetchCartDetails();
      fetchFavouriteProducts();
    }

    setActiveCategory(categoryList[0]);
  }, [isLoggedIn]);

  const [activeCategory, setActiveCategory] = useState({});

  const imgUrl = [
    {
      url : 'https://cdn01.pharmeasy.in/dam/banner/banner/5d88794b283-PHTC25.jpg'
    },
    {
      url : 'https://cdn01.pharmeasy.in/dam/banner/banner/d44329977db-gfdrdrt.jpg'
    },
    {
      url : 'https://cdn01.pharmeasy.in/dam/banner/banner/675b7113c65-Banner.jpg'
    },
  ]

  const imgUrl2 = [
    {
      url : 'https://cms-contents.pharmeasy.in/homepage_top_categories_images/4cb2baf3234-Fullbody.png?dim=360x0'
    },
    {
      url : 'https://cms-contents.pharmeasy.in/homepage_top_categories_images/e1a18d8deac-Vitamins.png?dim=360x0'
    },
    {
      url : 'https://cms-contents.pharmeasy.in/homepage_top_categories_images/1e927857c26-Diabetes.png?dim=360x0'
    },
    {
      url : 'https://cms-contents.pharmeasy.in/homepage_top_categories_images/e1c60c444bf-Fever.png?dim=360x0'
    },
    {
      url : 'https://cms-contents.pharmeasy.in/homepage_top_categories_images/7b238cdbb60-womencare.png?dim=360x0'
    },
    {
      url : 'https://cms-contents.pharmeasy.in/homepage_top_categories_images/71fb3c06e71-Thyroid.png?dim=360x0'
    },
    {
      url : 'https://cms-contents.pharmeasy.in/homepage_top_categories_images/6b775dd8478-covid.png?dim=360x0'
    },
  ]

  const [query, setQuery] = useState('')
  const debouncedSearch = useDebounce(query, 500);

  return (
    <div className="home">
      {
        isLoggedIn &&
        <div 
        className="w-full flex flex-row items-center justify-center relative"
        style={{
          backgroundImage : 'radial-gradient(150px 150px at 95% 0%, rgba(253, 186, 43, 0.3) 0%, rgba(253, 186, 43, 0) 100%), radial-gradient(150px 150px at 5% 0%, rgba(120, 213, 242, 0.3) 0%, rgba(253, 186, 43, 0) 100%)',

          height :'15rem',
        }}
      >
        <Container1 
          header1={'What are you looking for?'}
          className={'flex flex-col gap-20 relative'}
        >
         {
          <div 
            className="flex flex-row items-center gap-15 p-3 radius-secondary border-first search-box relative"
            >
            <CiSearch size={'1.5rem'} color={'#30363C'} />
            <input 
              id = "search-prod"
              type={'search'}
              placeholder="Search for medical..."
              className="w-full text-large"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value)
              }}
            />
          </div>
         }
          <div
            className="w-full relative"
          >
          {
            debouncedSearch.length != 0 &&
            <SearchResults
              search={debouncedSearch}
            />
          }
          </div>
        </Container1>
      </div>
      }
      
      { isLoggedIn &&  <Banner />}

      <Offers/>

      {
        isLoggedIn &&
        <Container1
          header1={'Shop By Categories'}
          className="p-3 flex flex-col gap-20"
        >
        <Caraousel>
          {
            categoryList.map((item) => {
                return (
                  <button
                    className="flex-col gap-10"
                    style={{
                      marginRight : '1%'
                    }}
                    onClick={() => {
                      history.push(`/categories/${item.name}`);
                    }}
                  >
                    <img
                      src={item.image}
                      className="radius-first card-hover"
                      style={{
                        width : '10rem',
                        height : '11rem',
                        objectFit : 'contain',
                        display : 'inline',
                      }}
                    />
                    <span className="text-large medium-bold">
                      {
                        item.name
                      }
                    </span>
                  </button>
                )
              }
            )
          }
          </Caraousel>
        </Container1>
      }

      {
        isLoggedIn &&
        categoryList.map((item) => {
          if(item.home_page && item.products.length > 0) 
            {
              return (
                <Container1 
                  header1={item.name}
                  className="w-full p-3 text-uppercase"
                >
                  <Foryou
                    title={item.name}
                    id={item.id}
                    pk={item.id}
                  />
                </Container1>
              )
            }
          }
        )
      }

      {
        !isLoggedIn && <WhyShop/>
      }
    </div>
  );
};

export default Home;
